<script lang="ts" setup>
import type { Placement } from '@floating-ui/vue';
import { watchDebounced } from '@vueuse/shared';
import _get from 'lodash/get';
import type { Ref } from 'vue';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { cn } from '../helpers/common';
import type { OptionSelect } from '../types/select';
import GLazyList from './GLazyList.vue';

import { flip, offset, shift, useFloating } from '@floating-ui/vue';
import { useInfiniteScroll } from '@vueuse/core';
import { useOutsideClick } from '../composables/useOutsideClick';
import GInput from './GInput.vue';

type DropdownSize = 'small' | 'medium' | 'large';
type Props = {
  options: OptionSelect[];
  activeId?: string | number;
  name?: string;
  searchable?: boolean;
  fixWidth?: boolean;
  searchTerm?: string;
  id?: string;
  selectBtnClasses?: string;
  dropdownClasses?: string;
  searchInputClass?: string;
  wrapperClass?: string;
  isLightMode?: boolean;
  disabled?: boolean;
  placeholder?: string;
  displayValue?: string;
  placement?: Placement;
  floating?: boolean;
  size?: DropdownSize;
  isSelectNumber?: boolean;
  checkIconEnable?: boolean;
  isFullWidth?: boolean;
  onSearch?: (value: string) => OptionSelect[];
};

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  searchable: false,
  fixWidth: false,
  searchTerm: 'name',
  disabled: false,
  placeholder: 'Search font family',
  floating: true,
  size: 'medium',
  isSelectNumber: false,
  placement: 'bottom-end',
  checkIconEnable: true,
});

const emit = defineEmits<{
  (e: 'select', value: any, id: string, selectName?: string): void;
  (e: 'on-scroll'): void;
  (e: 'on-search', value: string): void;
}>();

const gInputRef = ref<typeof GInput>();
const searchValue = ref('');
const filteredOptions = ref(props.options);
const isOpenSelect: Ref<boolean> = ref(false);
const select = ref<HTMLElement>();
const button = ref<HTMLElement>();
const scroll = ref<{ $el: HTMLElement | null }>({ $el: null });
const isDisplayOnBottom: Ref<boolean> = ref(true);
const isHoverSelect: Ref<boolean> = ref(false);

const isDisplayIconList = computed(() => props.displayValue === 'icon');
const iconBoundary = ref();

const getMaxWidthItemValue = (optionSelect: OptionSelect[]) => {
  if (props.fixWidth) return '248px';
  if (!optionSelect?.length) return '176px';
  const maxLength = Math.max(...optionSelect.map((item) => item.name.length || 0));
  const contentMaxLength = optionSelect.find((item) => item.name.length && item.name.length === maxLength)?.name;

  let width = 0;
  const itemIconWidth = 36;
  const itemContentPaddingLeft = 8;
  const listPaddingX = 8;
  const delta = 4;
  if (contentMaxLength) {
    const elm = document.createElement('div');
    elm.innerHTML = contentMaxLength;
    elm.style.fontSize = '12px';
    elm.style.height = 'auto';
    elm.style.width = 'auto';
    elm.style.position = 'absolute';
    elm.style.whiteSpace = 'no-wrap';
    elm.style.opacity = '0';
    document.body.appendChild(elm);
    width = Math.ceil(elm.clientWidth);
    document.body.removeChild(elm);
  }

  const listWidth = width + itemIconWidth + listPaddingX * 2 + itemContentPaddingLeft + delta;
  if (listWidth > 248) return '248px';
  else if (listWidth < 176) return '176px';
  return `${listWidth}px`;
};

const widthSelectBox = ref<string>(getMaxWidthItemValue(props.options));

const { x, y, strategy } = useFloating(button, select, {
  placement: props.placement,
  middleware: [flip(), shift(), offset(4)],
});

watch(
  () => props.options,
  (newValue) => {
    filteredOptions.value = newValue;
    widthSelectBox.value = getMaxWidthItemValue(newValue);
  },
);

watchDebounced(
  searchValue,
  () => {
    if (typeof props.onSearch === 'function') {
      emit('on-search', searchValue.value);
    } else {
      if (searchValue.value.trim().length > 0 && props.searchable) {
        filteredOptions.value = props.options.filter((option) => {
          return (_get(option, `${props.searchTerm}`) as string)
            .toLowerCase()
            .includes(searchValue.value.trim().toLowerCase());
        });
      } else {
        filteredOptions.value = props.options;
      }
    }
  },
  { debounce: 200 },
);

const toggleSelect = () => {
  if (isOpenSelect.value) {
    closeSelect();
    return;
  }
  openSelect();
};

const closeSelect = () => {
  isOpenSelect.value = false;
  resetStateDisplay();
};

const resetStateDisplay = () => {
  isDisplayOnBottom.value = true;
  isHoverSelect.value = false;
};

async function openSelect() {
  isOpenSelect.value = true;
  await nextTick();
  if (gInputRef.value) {
    gInputRef.value.focus();
  }
}

const onSelect = (item: OptionSelect) => {
  if (!isActiveOption(item)) {
    emit('select', item.value, item.id, props.name);
  }
  closeSelect();
};

const isActiveOption = (item: OptionSelect) => {
  return String(props.activeId) === String(item.id);
};

const changeStatusHoverSelect = () => {
  isHoverSelect.value = true;
};

const scrollToActiveOption = () => {
  if (!scroll.value?.$el) {
    return;
  }

  scroll.value.$el.scrollTop = getActiveOptionOffsetTop();
};

const getActiveOptionOffsetTop = () => {
  const $activeOption = findActiveOptionDom();
  const clientHeightScroll = scroll.value?.$el?.clientHeight || 0;
  const offsetTop = $activeOption?.offsetTop || 0;
  if (offsetTop < clientHeightScroll) {
    return 0;
  }
  return offsetTop;
};

const findActiveOptionDom = () => {
  return scroll.value?.$el?.querySelector(`[data-value="${props.activeId}"]`) as HTMLElement;
};

onMounted(() => {
  useOutsideClick(select, closeSelect, { detectIframe: true, contains: [button] });
});

useInfiniteScroll(
  iconBoundary,
  () => {
    emit('on-scroll');
  },
  { distance: 20 },
);

watch(scroll, () => {
  scrollToActiveOption();
});

const isSmallSize = computed(() => {
  return props.size == 'small';
});

const isMediumSize = computed(() => {
  return props.size == 'medium';
});

const isLargeSize = computed(() => {
  return props.size == 'large';
});
</script>

<template>
  <div class="relative w-full rounded-xl" :class="[isLightMode ? 'bg-light-300' : 'bg-dark-400', wrapperClass]">
    <button
      :id="id"
      ref="button"
      data-test="editor-control-g-select-button"
      class="border-transparent"
      :disabled="disabled"
      :class="
        cn(
          'relative flex w-full items-center rounded-xl border border-transparent bg-transparent px-8 outline-none transition duration-200 disabled:cursor-not-allowed',
          {
            '!border-primary-300': isOpenSelect,
          },
          selectBtnClasses,
          isLightMode
            ? 'border-primary-300 text-text-light-500 hover:bg-light-450 active:border-light-300'
            : 'text-light-450 hover:bg-dark-200 placeholder:text-text-dark-100 disabled:border-dark-200 disabled:text-text-dark-100 ',
          {
            'text-12 h-32': isSmallSize,
            'text-12 h-36': isMediumSize,
            'text-14 h-40': isLargeSize,
          },
        )
      "
      @click="toggleSelect">
      <div class="flex w-full items-center justify-between">
        <div class="flex w-[calc(100%_-_24px)] overflow-hidden">
          <slot name="button-content" :is-open="isOpenSelect" />
        </div>
        <slot name="icon-content">
          <svg
            v-if="size == 'small'"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.13017 6.11716C4.30374 5.96095 4.58515 5.96095 4.75871 6.11716L8 9.03431L11.2413 6.11716C11.4149 5.96095 11.6963 5.96095 11.8698 6.11716C12.0434 6.27337 12.0434 6.52663 11.8698 6.68284L8.31427 9.88284C8.1407 10.0391 7.8593 10.0391 7.68573 9.88284L4.13017 6.68284C3.95661 6.52663 3.95661 6.27337 4.13017 6.11716Z"
              :fill="isLightMode ? '#676767' : '#E2E2E2'" />
          </svg>
          <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.13313 7.62204C5.31064 7.45932 5.59845 7.45932 5.77596 7.62204L10 11.4941L14.224 7.62204C14.4016 7.45932 14.6894 7.45932 14.8669 7.62204C15.0444 7.78476 15.0444 8.04858 14.8669 8.21129L10.3214 12.378C10.1439 12.5407 9.8561 12.5407 9.67859 12.378L5.13313 8.21129C4.95562 8.04858 4.95562 7.78476 5.13313 7.62204Z"
              :fill="isLightMode ? '#676767' : '#E2E2E2'" />
          </svg>
        </slot>
      </div>
    </button>
    <div
      v-if="isOpenSelect"
      ref="select"
      v-click-away="closeSelect"
      :style="{
        position: strategy,
        top: y ? `${y}px` : '',
        left: floating ? (x ? `${x}px` : '0px') : undefined,
        ...(isFullWidth ? {} : { width: isSelectNumber ? '96px' : widthSelectBox }),
      }"
      :class="
        cn(
          'rounded-12 text-16 shadow-2dp sm:text-14 absolute right-0 z-[999] py-8 focus:outline-none',
          isLightMode ? 'bg-white' : 'bg-dark-400',
          dropdownClasses,
        )
      "
      @mousemove="changeStatusHoverSelect">
      <GInput
        v-if="searchable"
        ref="gInputRef"
        size="small"
        class="px-12"
        input-style="secondary"
        :value="searchValue"
        :classes="searchInputClass"
        :searchable="true"
        :placeholder="placeholder"
        @on-change="(value: string) => (searchValue = value)">
        <template #icon>
          <GBaseIcon class="text-white" name="search" width="16" height="16" />
        </template>
        <template #clear>
          <button
            v-if="searchValue"
            class="absolute right-0 flex w-32 justify-center p-8 text-center"
            data-test="editor-control-g-input-clear"
            @click.stop="searchValue = ''">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.85355 5.14645C5.65829 4.95118 5.34171 4.95118 5.14645 5.14645C4.95118 5.34171 4.95118 5.65829 5.14645 5.85355L7.29289 8L5.14645 10.1464C4.95118 10.3417 4.95118 10.6583 5.14645 10.8536C5.34171 11.0488 5.65829 11.0488 5.85355 10.8536L8 8.70711L10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536C11.0488 10.6583 11.0488 10.3417 10.8536 10.1464L8.70711 8L10.8536 5.85355C11.0488 5.65829 11.0488 5.34171 10.8536 5.14645C10.6583 4.95118 10.3417 4.95118 10.1464 5.14645L8 7.29289L5.85355 5.14645Z"
                fill="#E2E2E2"></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"
                fill="#E2E2E2"></path>
            </svg>
          </button>
        </template>
      </GInput>
      <GLazyList
        v-if="!isDisplayIconList"
        :searchable="searchable"
        :data="filteredOptions"
        :items-per-render="15"
        :is-hover-select="isHoverSelect"
        :on-select="onSelect">
        <template #default="{ item }">
          <div
            :class="
              cn(
                'text-12 flexcursor-pointer relative select-none items-center rounded-xl py-[9px] leading-[150%]',
                {
                  'cursor-default': isActiveOption(item),
                },
                isLightMode ? 'text-text-light-500 hover:bg-light-400' : 'hover:bg-light-100/20 text-light-450',
                checkIconEnable ? 'pl-36 pr-8' : 'text-center',
              )
            "
            :data-value="item.id"
            data-test="select-option"
            @click="onSelect(item)">
            <span
              v-if="isActiveOption(item) && checkIconEnable"
              class="absolute inset-y-0 left-0 flex items-center px-8">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.7471 5.30122C16.9912 5.5453 16.9912 5.94103 16.7471 6.18511L7.63139 15.3008C7.51418 15.418 7.35521 15.4839 7.18945 15.4839C7.02368 15.4839 6.86471 15.418 6.7475 15.3008L3.25141 11.8046C3.00734 11.5605 3.00734 11.1648 3.25143 10.9207C3.49551 10.6766 3.89124 10.6766 4.13531 10.9207L7.18946 13.975L15.8632 5.30122C16.1073 5.05714 16.503 5.05714 16.7471 5.30122Z"
                  fill="#00C853" />
              </svg>
            </span>
            <div class="flex flex-col gap-4">
              <div
                class="font-regular flex w-full justify-between"
                :class="{
                  '!justify-center': !checkIconEnable,
                }">
                <template v-if="item.svgIcon">
                  <span v-html="item.svgIcon" />
                </template>
                <span v-html="item.name"></span>
                <span v-if="item.value?.des" class="text-text-dark-300">{{ item.value.des }}</span>
                <template v-if="item.value?.icon">
                  <g-base-icon
                    class="text-text-dark-300"
                    width="20px"
                    height="20px"
                    :name="item.value.icon"></g-base-icon>
                </template>
                <template v-if="item.value?.svgIcon">
                  <span v-html="item.value?.svgIcon" />
                </template>
              </div>
              <div v-if="item.value?.note" class="text-text-dark-100 text-12 font-regular">
                {{ item.value?.note }}
              </div>
            </div>
          </div>
        </template>
      </GLazyList>
      <div v-else class="h-[156px] py-8" data-test="editor-control-setting-select-icon">
        <perfect-scrollbar ref="iconBoundary" class="px-12">
          <div class="grid grid-cols-6">
            <div
              v-for="(icon, index) in filteredOptions"
              :key="index"
              class="rounded-12 hover:bg-dark-50 mb-4 flex h-32 w-32 cursor-pointer items-center justify-center"
              :data-test="`editor-control-icon-${icon.name}`"
              @click.stop="onSelect(icon)">
              <svg fill="white" xmlns="http://www.w3.org/2000/svg" class="h-16 w-16" viewBox="0 0 512 512">
                <path strokeLinecap="round" strokeLinejoin="round" :d="icon.value" />
              </svg>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
      <slot name="footer" />
    </div>
  </div>
</template>
