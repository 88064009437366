<template>
  <div :class="cn('gemx-control', { 'opacity-50': disabled }, classContainer)">
    <div class="gemx-checkbox-group relative">
      <div class="flex cursor-pointer items-center" @click.stop="handleToggleValue">
        <div
          class="group-hover:bg-overlay-grey group-hover/white:bg-overlay-grey group-hover/dark:bg-overlay-white pointer-events-none absolute left-[-5px] z-0 h-28 w-28 rounded-[3px]"></div>
        <input
          v-model="val"
          class="rounded-medium z-5 h-[16px] w-[16px] cursor-pointer bg-blue-300"
          :class="inputClasses"
          type="checkbox"
          :disabled="disabled"
          :value="value" />
        <label
          class="text-dark-400 text-14 z-5 cursor-pointer select-none pl-8 leading-6"
          :class="labelClasses"
          :for="value"
          @click.stop="handleToggleValue">
          <span v-html="text"></span>
        </label>
      </div>
      <p class="gemx-checkbox-desc">{{ desc }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { cn } from '../helpers/common';
const props = defineProps<{
  value?: any;
  text?: string;
  desc?: string;
  classContainer?: string;
  inputClasses?: string;
  labelClasses?: string;
  disabled?: boolean;
}>();

const val = ref(props.value);

const emit = defineEmits<{
  (e: 'onChange', controlId?: string | number, value?: any): void;
  (e: 'onFocus', controlId?: string | number, value?: any): void;
  (e: 'onBlur', controlId?: string | number, value?: any): void;
}>();

const setValue = (value: any) => {
  if (value != val.value) {
    val.value = value;
  }
};

const handleToggleValue = () => {
  if (props.disabled) return;
  val.value = !val.value;
};

watch(val, (value) => {
  setValue(value);
  change();
});

watch(
  () => props.value,
  (value) => {
    val.value = value;
  },
);

const change = () => {
  emit('onChange', val.value);
};
</script>
