<script lang="ts" setup>
import { computed } from 'vue';
import { cn } from '../helpers/common';
import type { ButtonType } from '../types/button';

const props = withDefaults(
  defineProps<{
    cls?: string;
    size?: 'regular';
    buttonType?: ButtonType;
    overlayContainer?: string;
    closeable?: boolean;
    cursor?: string;
    isOpenModal?: boolean;
  }>(),
  {
    size: 'regular',
    buttonType: 'grey',
    closeable: true,
  },
);

const emit = defineEmits<{
  (e: 'open'): void;
  (e: 'close'): void;
  (e: 'onShowDom'): void;
}>();

const clsWBySize = computed(() => {
  switch (props.size) {
    case 'regular':
      return 'w-[257px]';
    default:
      return '';
  }
});
const cursorID = computed(() => `btn-more-setting-${props.cursor}`);
const isUseCloseable = computed(() => !!props.closeable);

const onShowDomMoreSetting = () => {
  emit('onShowDom');
};
const onOpen = () => {
  emit('open');
};
const onClose = () => {
  emit('close');
};
</script>

<template>
  <GPopover
    class="gemx-page-item-disable-link"
    :closeable="isUseCloseable"
    placement="bottom-end"
    :prevent-default="true"
    strategy="absolute"
    arrow-class="text-white hidden"
    :cls="cn('!p-0 absolute z-40', clsWBySize, cls)"
    :overlay-container="overlayContainer"
    :is-hidden-dom="true"
    :is-toggle-open="true"
    :is-open-modal="isOpenModal"
    :ignore-outside-class="cursorID"
    @open="onOpen"
    @close="onClose">
    <div :class="cursorID">
      <GButtonV2
        data-test="db-page-btn-more"
        :type="buttonType"
        size="medium"
        only-icon="more-setting-20"
        @click="onShowDomMoreSetting">
      </GButtonV2>
    </div>
    <template #content="{ open, close }">
      <div class="bg-light-100 rounded-medium shadow-2dp flex h-full w-full flex-col overflow-hidden p-8">
        <slot name="content-setting" v-bind="{ open, close }"></slot>
      </div>
    </template>
  </GPopover>
</template>
