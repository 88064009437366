<template>
  <span data-test="editor-header-control-page-status" class="ml-8 cursor-default">
    <g-badge
      v-if="status == 'default'"
      icon="dot-primary"
      status="Default"
      :last-action="defaultTooptip"
      class-color="text-green-200"
      class-container="bg-underlay-green-100"
      tooltip-content-class="maxw-[300px] whitespace-normal"></g-badge>
    <g-badge
      v-else-if="status == 'draft'"
      icon="dot-primary"
      status="Draft"
      :last-action="lastAction"
      class-color="text-primary-200"
      :hidden-text="hiddenText"
      :hidden-tooltip="hiddenTooltip"
      class-container="bg-underlay-blue-150"></g-badge>
    <g-badge
      v-else-if="status === 'needRepublish'"
      icon="dot-primary"
      status="Need republishing"
      :last-action="lastAction"
      :hidden-text="hiddenText"
      :hidden-tooltip="hiddenTooltip"
      class-color="text-yellow-300"
      class-container="bg-underlay-yellow-100"></g-badge>
    <g-badge
      v-else
      icon="dot-primary"
      status="Published"
      :last-action="lastAction"
      :hidden-text="hiddenText"
      :hidden-tooltip="hiddenTooltip"
      class-color="text-green-200"
      class-container="bg-underlay-green-100"></g-badge>
  </span>
</template>
<script lang="ts" setup>
import { toRefs, computed } from 'vue';
import GBadge from '../../base/GBadge.vue';
import { toDateString } from '../../helpers/common';

type Props = {
  status?: 'needRepublish' | 'published' | 'draft' | 'default';
  lastUpdatedAt?: string;
  hiddenText?: boolean;
  hiddenTooltip?: boolean;
  defaultTooptip?: string;
};
const props = defineProps<Props>();
const { lastUpdatedAt, status } = toRefs(props);
const lastAction = computed(() => {
  if (status?.value === 'published') {
    return `Published at ${toDateString(lastUpdatedAt?.value || new Date().toString())}`;
  }
  return `Last modified ${toDateString(lastUpdatedAt?.value || new Date().toString())}`;
});
</script>
