<script lang="ts" setup>
import PageStatus from './PageStatus.vue';

import { ref, watch, computed } from 'vue';

type Props = {
  pageType?: {
    name?: string;
    icon?: string;
    key?: string;
  };
  pageName?: string;
  isEditThemeSection?: boolean;
  publishStatus?: boolean;
  lastAction?: string;
  updatedAt?: string;
  publishedAt?: string | null;
  pageStatus?: string;
  hiddenStatusText?: boolean;
  hiddenTooltip?: boolean;
  isOrigin?: boolean;
  isDefault?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  pageName: 'Untitled',
  isEditThemeSection: false,
  updatedAt: new Date().toString(),
});

const emit = defineEmits<{
  (e: 'switch-page'): void;
}>();

const updatedTime = ref(props.updatedAt);

const statusPage = computed(() => {
  if (props.pageStatus) {
    switch (props.pageStatus) {
      case 'DRAFT':
        return 'draft';
      case 'PUBLISHED':
      case 'PUBLISHING':
      case 'PUBLISH_ERROR':
        return props.publishedAt || props.isOrigin ? 'published' : 'draft';
      case 'NEED_PUBLISHING':
        return 'needRepublish';
      default:
        return 'draft';
    }
  }
  if (!props.publishStatus && !props.isOrigin) {
    return 'draft';
  } else if (props.lastAction === 'pageSave') return 'needRepublish';
  return 'published';
});

const defaultTooptip = computed(() => {
  if (props.isDefault) {
    switch (props.pageType?.name) {
      case 'Home Page':
        return 'This is the only Home page of your store';
      case 'Product Page':
        return 'All products not assigned to any template will be displayed by default with this template';
      case 'Collection Page':
        return 'All collections not assigned to any template will be displayed by default with this template';
    }
  }
  return '';
});

watch(
  () => props.pageStatus,
  () => {
    updatedTime.value = new Date().toString();
  },
);
</script>

<template>
  <div class="rounded-medium hidden h-40 flex-1 items-center justify-center px-16 after:flex-row sm:flex">
    <template v-if="pageType">
      <g-tooltip placement="bottom" :margin-top="8" class="flex rounded-xl py-4">
        <div
          data-test="editor-header-control-link-page-type"
          class="text-light-500 hover:bg-dark-250 flex h-40 cursor-pointer select-none items-center gap-4 whitespace-nowrap rounded-xl px-8 py-4 transition-all duration-200"
          @click="emit('switch-page')">
          <span>
            <g-base-icon :name="(pageType.icon as any)" width="20" height="20"></g-base-icon>
          </span>
          <span class="font-regular inline-flex text-[14px]"> / </span>
          <div class="flex items-center">
            <span
              class="text-14 line-clamp-1 cursor-pointer overflow-hidden text-ellipsis whitespace-pre-wrap break-all text-start font-medium">
              {{ pageName }}
            </span>
          </div>
          <span class="items-center">
            <g-base-icon name="chevron-down" viewBox="0 0 14 14" width="20" height="20"></g-base-icon>
          </span>
        </div>
        <template #content
          ><span class="text-12 flex items-center">{{ `View in ${pageType?.name} list` }}</span></template
        >
      </g-tooltip>
    </template>
    <slot name="switch-page"></slot>
    <div class="static ml-8 hidden items-center sm:flex">
      <PageStatus
        v-if="!isEditThemeSection && isDefault"
        class="lss:block hidden"
        status="default"
        :default-tooptip="defaultTooptip"></PageStatus>

      <PageStatus
        v-if="!isEditThemeSection"
        class="lss:block hidden"
        :hidden-text="hiddenStatusText"
        :status="statusPage"
        :last-updated-at="updatedTime"
        :hidden-tooltip="hiddenTooltip"></PageStatus>
    </div>
    <div>
      <slot name="more"></slot>
    </div>
  </div>
</template>
