import type { Plugin } from 'vue';
import GTipBox from './base/GTipBox.vue';
import GBaseIcon from './base/GBaseIcon.vue';
import GButtonV2 from './v2/base/GButton.vue';
import GButton from './base/GButton.vue';
import GChip from './base/GChip.vue';
import GBadge from './base/GBadge.vue';
import GImage from './base/GImage.vue';
import GInput from './base/GInput.vue';
import GLoadingPlaceholder from './base/GLoadingPlaceholder.vue';
import GSelect from './base/GSelect.vue';
import GText from './base/GText.vue';
import GTitle from './base/GTitle.vue';
import GTooltip from './base/GTooltip.vue';
import GModal from './base/GModal.vue';
import GPopover from './base/GPopover.vue';
import GReorder from './base/re-order/ReOrder.vue';
import GDropdown from './elements/Dropdown.vue';
import GLogo from './components/header/TheLogo.vue';
import GMenuItem from './elements/MenuItem.vue';
import GPublishItem from './elements/PublishItem.vue';
import GPublishDomain from './elements/PublishDomain.vue';
import GLoading from './elements/loading/Loading.vue';
import GLoadingPages from './elements/loading/LoadingPages.vue';
import GLoadingPageHeader from './elements/loading/LoadingPageHeader.vue';
import GPopoverSidebar from './base/GPopoverSidebar.vue';
import GPublish from './components/header/PublishButton.vue';
import GUndoRedo from './components/header/UndoRedo.vue';
import GDeviceList from './components/header/TheDevices.vue';
import GAfterPublish from './components/header/AfterPublish.vue';
import GPublishConfirm from './components/header/PublishConfirm.vue';
import GHeader from './sections/Header.vue';
import GHeaderPage from './components/header/PageSetting.vue';
import GSwitchPage from './components/header/SwitchPage.vue';
import GSeoInfo from './components/publish-settings/SEOInfo.vue';
import GLayoutSetting from './components/publish-settings/LayoutSetting.vue';
import ClickAway, { vClickAway } from './directives/clickAway';
import GDomainItem from './components/manage-domains/DomainItem.vue';
import GAddDomain from './components/manage-domains/AddDomainArea.vue';
import GTabScroll from './elements/TabScroll.vue';
import GTabScrollItem from './elements/TabScrollItem.vue';
import GLogoLoading from './elements/loading/LogoLoading.vue';
import GTag from './base/GTag.vue';
import GSocialSharing from './components/publish-settings/SocialSharing.vue';
import GFavicon from './components/publish-settings/Favicon.vue';
import GPublishLayout from './components/publish-settings/Layout.vue';
import GFilter from './elements/Filter.vue';
import GInputSearch from './base/GInputSearch.vue';
import GGlobalSectionItem from './elements/GGlobalSectionItem.vue';
import GGlobalPageItem from './elements/GGlobalPageItem.vue';
import GProgress from './components/progress/Progress.vue';
import GSyncProgress from './components/progress/SyncProgress.vue';
import GPagination from './elements/Pagination.vue';
import GProgressBar from './elements/ProgressBar.vue';
import GTab from './elements/Tab.vue';
import GMultiCheckbox from './elements/GMultiCheckbox.vue';
import GCollapseTransition from './elements/GCollapseTransition.vue';
import GNotification from './components/notification/Notification.vue';
import GToggler from './base/GToggler.vue';
import GCheckbox from './base/GCheckbox.vue';
import GBanner from './base/GBanner.vue';
import GLazyList from './base/GLazyList.vue';
import DeviceSelectPopup from './components/device-select/DeviceSelectPopup.vue';
import CircleLogoLoading from './elements/loading/CircleLogoLoading.vue';
import GThemeSectionItem from './elements/GThemeSectionItem.vue';
import GMoreSetting from './more-setting/GMoreSetting.vue';
import GMoreSettingItem from './more-setting/GMoreSettingItem.vue';
import GAlert from './components/notification/GAlert.vue';
import GTabList from './components/tab-list/TabList.vue';
import GCountBadge from './components/CountBadge.vue';
import GTabs from './base/GTabs.vue';
import GDropdownMenu from './v2/base/GDropdownMenu.vue';
import GSidebarTabItem from './v2/components/GSidebarTabItem.vue';
import GSavedPostPurchaseItem from './elements/GSavedPostPurchaseItem.vue';

export * from './composables/useFloating';
export * from './composables/useFlip';

export {
  GTipBox,
  GLazyList,
  GCheckbox,
  GToggler,
  GButtonV2,
  GButton,
  GChip,
  GBadge,
  GBaseIcon,
  GInput,
  GSelect,
  GTitle,
  GText,
  GLoadingPlaceholder,
  GImage,
  GTooltip,
  GModal,
  GPopover,
  GDropdown,
  GMenuItem,
  GLogo,
  GPopoverSidebar,
  GPublish,
  GPublishDomain,
  GPublishItem,
  GDeviceList,
  GUndoRedo,
  GHeader,
  GHeaderPage,
  GSwitchPage,
  GSeoInfo,
  ClickAway,
  vClickAway,
  GTabScroll,
  GTabScrollItem,
  GDomainItem,
  GAddDomain,
  GTag,
  GSocialSharing,
  GPublishLayout,
  GReorder,
  GFilter,
  GPagination,
  GProgressBar,
  GTab,
  GMultiCheckbox,
  GFavicon,
  GInputSearch,
  GAfterPublish,
  GPublishConfirm,
  GGlobalSectionItem,
  GGlobalPageItem,
  GCollapseTransition,
  GLogoLoading,
  GLoading,
  GLoadingPages,
  GLoadingPageHeader,
  GProgress,
  GSyncProgress,
  GNotification,
  GLayoutSetting,
  DeviceSelectPopup,
  CircleLogoLoading,
  GThemeSectionItem,
  GSavedPostPurchaseItem,
  GMoreSetting,
  GMoreSettingItem,
  GAlert,
  GTabs,
  GTabList,
  GBanner,
  GDropdownMenu,
  GSidebarTabItem,
  GCountBadge,
};

export type { Thumbnail } from './types/image';
export type { FilterType, Tab } from './types/tab';
export type { OptionSelect } from './types/select';
export type { StepAddDomain, ManageDomainStatus } from './types/domain';

export { useOutsideClick } from './composables/useOutsideClick';
export * from './helpers/common';
/**
 * Init BaseComponent
 */
export const initUIKit: Plugin = {
  install(app) {
    // Only global base component
    app.component('GButton', GButton);
    app.component('GButtonV2', GButtonV2);
    app.component('GTipBox', GTipBox);
    app.component('GTitle', GTitle);
    app.component('GText', GText);
    app.component('GTooltip', GTooltip);
    app.component('GInput', GInput);
    app.component('GImage', GImage);
    app.component('GLoadingPlaceholder', GLoadingPlaceholder);
    app.component('GBaseIcon', GBaseIcon);
    app.component('GModal', GModal);
    app.component('GPopover', GPopover);
    app.component('GPopoverSidebar', GPopoverSidebar);
    app.component('GDropdown', GDropdown);
    app.component('GMenuItem', GMenuItem);
    app.component('GTag', GTag);
    app.component('GFilter', GFilter);
    app.component('GPagination', GPagination);
    app.component('GInputSearch', GInputSearch);
    app.component('GSelect', GSelect);
    app.component('GCheckbox', GCheckbox);
    app.component('GToggler', GToggler);
    app.component('GLazyList', GLazyList);
    app.component('GTabList', GTabList);
    app.component('GBanner', GBanner);
    // Only global base component
  },
};
